const DIAG_QUERY_PARAMS = {
  EMBEDDED: 'om-embedded-diag-id',
  POPUP: 'om-popup-diag-id',
};

const isPopup = (campaign) => {
  return !['embedded', 'dynamic_content'].includes(campaign?.templateType);
};

const startDiag = ({ domain, param, campaignId }) => {
  const url = new URL(`https://${domain}`);
  const searchParams = new URLSearchParams();
  searchParams.append(param, campaignId);
  url.search = searchParams;

  window.open(url.href, '_blank');
};

export default {
  methods: {
    isCampaignDiagnosticAvailable(campaign) {
      return campaign?.status === 'active' && isPopup(campaign); // It will be available later for others.
    },
    startDiagnosticateCampaign(campaign) {
      startDiag({
        domain: campaign.domain,
        param: isPopup(campaign) ? DIAG_QUERY_PARAMS.POPUP : DIAG_QUERY_PARAMS.EMBEDDED,
        campaignId: campaign.id,
      });
    },
  },
};
