<template lang="pug">
popper(
  v-if="showNewDiagFeaturePopover"
  key="campaignsStatusSelectPopper"
  :force-show="true"
  trigger="click"
  :options="{ placement: 'left-start' }"
  transition="fade"
  enter-active-class="fade-enter-active"
  leave-active-class="fade-leave-active"
)
  .popper.mt-3.brand-tooltip.brand-tooltip-light.brand-tooltip-left
    .popper-diagnostic-content
      .popper-diagnostic-content-close(@click="hideNewDiagFeaturePopover")
        div(@click="$emit('close')")
          uil-times.cursor-pointer(:size="'26px'")
      .popper-diagnostic-content-image(v-if="new Date() < new Date('2025-05-01')")
        img(:src="require('@/assets/admin/img/monk_magnifying.png')")
      .popper-diagnostic-content-new-badge
        span {{ $t('newDiagFeaturePopover.new') }}
      .popper-diagnostic-content-title {{ $t('newDiagFeaturePopover.title') }}
      .popper-diagnostic-content-description {{ $t('newDiagFeaturePopover.desc') }}
  div(slot="reference")
</template>

<script>
  import { UilTimes } from '@iconscout/vue-unicons';
  import cookie from '@/mixins/cookie';
  import { track } from '@/services/xray';

  const COOKIE_KEY_NEW_DIAG_FEATURE = 'x-om-seen-diag-feature';

  export default {
    components: { UilTimes },
    mixins: [cookie],
    props: {
      isCampaignDiagnosticAvailable: { type: Boolean },
    },
    data: () => ({
      showNewDiagFeaturePopover: false,
    }),
    watch: {
      isCampaignDiagnosticAvailable(value) {
        if (!value) return;
        if (this.getCookie(COOKIE_KEY_NEW_DIAG_FEATURE)) return;

        setTimeout(() => {
          this.setCookie(COOKIE_KEY_NEW_DIAG_FEATURE, 1);
          this.showNewDiagFeaturePopover = true;
          track('diagBannerShow');
        }, 1000);
      },
    },
    methods: {
      hideNewDiagFeaturePopover() {
        this.showNewDiagFeaturePopover = false;
      },
    },
  };
</script>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .popper-diagnostic-content
    font-family: 'Roboto'
    color: $om-gray-700
    width: 248px
    padding: 10px 10px 20px 10px
    div
      white-space: normal
    &-close
      position: absolute
      top: 15px
      right: 15px
    &-image
      display: flex
      justify-content: center
    &-new-badge
      padding-top: 15px
      span
        display: inline-block
        background: linear-gradient(180deg, #8444E1 0.01%, #ED5A29 100%)
        border-radius: 40px
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: #FFFFFF
        padding: 0 12px
    &-title
      padding: 15px 0
      font-weight: 700
      font-size: 16px
      line-height: 24px
    &-description
      font-weight: 400
      font-size: 14px
      line-height: 20px
</style>
